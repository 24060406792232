<template>
  <div>
    <template v-if="showLecs">
      <b-row v-if="currentLecs.length == 0" style="height: 20vh">
        <b-col md="12" class="text-center">
          <h1 class="mt-1 mb-1">No records found</h1>
          <b-button
            variant="outline-secondary"
            class=""
            @click="showLecs = false"
          >
            <feather-icon icon="ArrowLeftIcon" class="" />
            Back
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8">
          <b-breadcrumb class="mb-1 pt-0">
            <b-breadcrumb-item
              href="#"
              class="h4 mb-0 text-capitalize"
              @click="showLecs = false"
            >
              {{ lecObj.lecItem.subject }}
            </b-breadcrumb-item>
            <b-breadcrumb-item href="#" active class="h4 mb-0 text-capitalize">
              {{ lecObj.title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
          <!-- <h4 class="mb-1 text-capitalize">
            {{ lecObj.title }}
          </h4> -->
          <!-- <div v-if="vidLoading" class="text-center">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="loading"
            ></b-spinner>
          </div> -->
          <iframe
            class="border-0 rounded w-100"
            :src="lecObj.path"
            style="aspect-ratio: 16/9"
          ></iframe>
          <!-- @load="vidLoading = false" -->

          <b-media vertical-align="center" class="my-1">
            <template #aside>
              <b-avatar
                size="44"
                :src="lecObj.lecItem.picture"
                variant="light-primary"
              />
            </template>
            <h4 class="mb-25">{{ lecObj.lecItem.teacher }}</h4>
            <b-badge variant="light-primary">
              {{
                new Date(lecObj.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </b-media>
          <p>{{ lecObj.description }}</p>
        </b-col>
        <b-col md="4">
          <h4 class="mb-1">More Lectures</h4>
          <b-card
            v-for="(item, ind) in otherLecs"
            :key="ind"
            border-variant="primary"
            class="cursor-pointer"
            @click="openOther(item)"
          >
            <h5 class="text-capitalize">{{ item.title }}</h5>
            <b-badge variant="light-primary">
              {{
                new Date(item.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row align-h="center" v-if="dataLoading">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
      <b-row v-else-if="items.length == 0" style="height: 20vh">
        <b-col md="12">
          <h1 class="mt-1 mb-1 text-center">No records found</h1>
        </b-col>
      </b-row>
      <b-row v-else class="mt-2">
        <b-col
          v-for="(item, index) in items"
          :key="index"
          class=""
          xl="3"
          lg="4"
          md="4"
          sm="6"
        >
          <b-card no-body class="cursor-pointer" @click="openLecs(item)">
            <b-card
              :bg-variant="color[index % color.length]"
              class="mb-0"
              style="
                min-height: 130px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              "
              body-class="p-1"
            >
              <h1 class="text-white mb-1">{{ item.subject }}</h1>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="item.picture"
                    variant="light-light"
                  />
                </template>
                <h4 class="text-white mb-0">{{ item.teacher }}</h4>
              </b-media>
            </b-card>
            <h4 class="text-center p-1 mb-0">({{ item.total }}) Lectures</h4>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BSpinner,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BSpinner,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      dataLoading: false,
      items: [
        {
          id: 1,
          subject: "Web & App",
          teacher: "Muhammad Ali",
          picture: "https://cdn.cloudious.net/file-1678094351350-637549624.png",
          total: 2,
        },
      ],
      lectures: [
        {
          id: 1,
          playlistID: 1,
          title: "Check student's Attendance",
          description:
            "The best school management system introduced how to check student's attendance",
          path: "https://cdn.cloudious.net/file-1721391494022-638284695.mp4",
          date: "2024-04-06T10:00:00",
        },
        {
          id: 2,
          playlistID: 1,
          title: "Check student's dues and payment history",
          description:
            "Check online student's dues and payment history on myskool app - The Best school Management",
          path: "https://cdn.cloudious.net/file-1721399733592-940220300.mp4",
          date: "2024-04-07T10:00:00",
        },
      ],
      currentLecs: [],
      showLecs: false,
      lecObj: {},
      vidLoading: false,
    };
  },
  computed: {
    otherLecs() {
      return this.currentLecs.filter((el) => el.id != this.lecObj.id);
    },
  },
  methods: {
    openLecs(item) {
      this.currentLecs = this.lectures.filter((el) => el.playlistID == item.id);
      if (this.currentLecs.length > 0) {
        this.lecObj = this.currentLecs[0];
        this.lecObj.lecItem = item;
      } else this.lecObj = {};
      this.showLecs = true;
      this.vidLoading = true;
    },
    openOther(item) {
      this.lecObj = { lecItem: this.lecObj.lecItem, ...item };
    },
  },
};
</script>
<style scoped>
.media {
  align-items: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
